import axios from "axios";
let baseURL = null;

if (process.env.REACT_APP_INCOME_BACKEND_URL) {
  baseURL = process.env.REACT_APP_INCOME_BACKEND_URL;
}

const axiosClient = axios.create({
  baseURL,
  responseType: "json",
});

export default axiosClient;

import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as LogoImg } from "../../../assets/images/logo.svg";
import MobileMenuImg from "../../../images/landings/mobile-menu.png";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import classNames from "classnames";
import LandingLanguages from "./LandingLanguages";

const Header = () => {
  const [modalMenu, toggleMenu] = useState(false);
  const headerNavigation = (inModal: boolean) => (
    <div
      className={classNames("header__navigation-wrap", {
        "header__navigation-wrap--modal": inModal,
      })}
    >
      <ul className={"nav-menu"}>
        <li className="nav-menu__item">
          <NavLink
            to={APP_ROUTES.commissions.commission}
            className={"nav-menu__link"}
          >
            {_t("Commissions")}
          </NavLink>
        </li>
        <li className="nav-menu__item">
          <NavLink to={APP_ROUTES.faq.index} className={"nav-menu__link"}>
            {_t("FAQ")}
          </NavLink>
        </li>
        <li className="nav-menu__item">
          <NavLink to={APP_ROUTES.contacts} className={"nav-menu__link"}>
            {_t("Contacts")}
          </NavLink>
        </li>
      </ul>
      <LandingLanguages/>
      <div className="header__button-wrap">
        <a href={_t('dashboard_link_sign_up')} rel='noreferrer noopener' target="_blank"  className="btn">
          {_t("sign_up")}
        </a>
        <a href={_t('dashboard_link_sign_in')} rel='noreferrer noopener' target="_blank"  className="btn btn--default">
          {_t("sign_in")}
        </a>
      </div>
    </div>
  );

  return (
    <header className="header">
      <div className="container">
        <div className="header__wrap">
          <NavLink to={"/"} className={"header__logo-link"}>
            <div className="header__logo-cont">
              <LogoImg className={"header__logo-img"} />
            </div>
          </NavLink>

          {headerNavigation(false)}

          <button className="burger-menu" onClick={() => toggleMenu(true)}>
            <img src={MobileMenuImg} alt="burger" />
          </button>

          <div className={classNames("menu-modal", modalMenu && "opened")}>
            <button
              type="button"
              className="close-modal-button"
              onClick={() => toggleMenu(false)}
            />
            {headerNavigation(true)}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
